import React from 'react'
import healthier from "../../assets/kids/healthier.png"
import learn from "../../assets/kids/learn.png"
import emotion from "../../assets/kids/emotion.png"
import behaved from "../../assets/kids/behaved.png"
import selfEsteem from "../../assets/kids/self-esteem.png"
import eq from "../../assets/kids/eq.png"
import anger from "../../assets/kids/anger.png"
import academic from "../../assets/kids/academic.png"
import innovate from "../../assets/kids/innovate.png"
import VHCenter from "../elements/VHCenter"
import './children.css'

export default function Children({ icon, heading, width, height }) {
    return (
        <div className="col-6 col-sm-6 col-md-3 text-center">
            <div style={{ width: "100px", height: "100px", margin: "auto", }}>
                <VHCenter>
                    <img width={width} height={height} src={icon} alt={heading} />
                </VHCenter>
            </div>
            <h6 className="avenir-black" style={{ marginTop: 0, fontWeight: 900, color: "rgb(97, 94, 94)", fontSize: "17px" }}>{heading}</h6>
        </div>
    )
}
