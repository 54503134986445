// This component lists the happiness ftt Values section on Ourstory page and HappyChildren Section on kids page

import React from 'react'
import Values from './ourstory/Values'
import Children from "./kids/Children"

import { graphql, useStaticQuery } from "gatsby";
import Help from './landingPage/Help';
import Methods from './discoverUs/Methods';

const ListingComponent = ({ path }) => {

  const data = useStaticQuery(graphql`
    query Values {
      ourstoryValues: allContentfulValues(
        filter: { path: { eq: "/ourstory" } },
        sort: { fields: orderId }
      )
      {
        edges {
          node {
            heading
            orderId
            icon{
              fluid{
                src
              }
            }
            width,
            height,
            text{
              childContentfulRichText{
                html
              }
            }
            
          }
        }
      }
      happyChildren: allContentfulValues(
        filter: { path: { eq: "/kids" } },
        sort: { fields: orderId }
      )
      {
        edges {
          node {
            heading
            orderId
            icon{
              fluid{
                src
              }
            }
            width,
            height,
          }
        }
      }
      
      discoverMethods: allContentfulValues(
        filter: { path: { eq: "/discover" } },
        sort: { fields: orderId }
      )
      {
        edges {
          node {
            heading
            orderId
            icon{
              fluid{
                src
              }
            }
            width,
            height,
          }
        }
      }
      }
    `)

  console.log("kids is", data.happyChildren)

  return (
    <div>
      <div className="container">
        <div className="row">
          {
            path == "/ourstory" ?
              data.ourstoryValues.edges.map((edge) => {
                return (
                  <Values
                    key={edge.node.orderId}
                    path="/ourstory"
                    heading={edge.node.heading}
                    width={edge.node.width}
                    height={edge.node.height}
                    text={edge.node.text.childContentfulRichText.html}
                    icon={edge.node.icon.fluid.src}
                  />
                )
              })
              :
              ""
          }
        </div>
        <div className="row">
          {
            path == "/kids" ?
              data.happyChildren.edges.map((edge) => {
                return (
                  <Children
                    path="/ourstory"
                    heading={edge.node.heading}
                    width={edge.node.width}
                    height={edge.node.height}
                    icon={edge.node.icon.fluid.src}
                  />
                )
              })
              :
              ""
          }
        </div>
        <div className="row" style={{ marginBottom: "5%" }}>
          {
            path == "/discover" ?
              data.discoverMethods.edges.map((edge) => {
                return (
                  <Methods
                    path="/discover"
                    heading={edge.node.heading}
                    width={edge.node.width}
                    height={edge.node.height}
                    icon={edge.node.icon.fluid.src}
                  />
                )
              })
              :
              ""
          }
        </div>
        {/* <div style={{ paddingBottom: "10%" }}>
          <div className="row center mb-lg-2 pb-lg-0 mb-5 pb-5" >
            {
              path == "/home" ?
                data.help.edges.map((edge) => {
                  return (
                    <Help
                      path="/home"
                      heading={edge.node.heading}
                      width={edge.node.width}
                      height={edge.node.height}
                      icon={edge.node.icon.fluid.src}
                      text={edge.node.text.childContentfulRichText.html}
                    />
                  )
                })
                :
                ""
            }
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default ListingComponent


