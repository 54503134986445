import React from 'react'
import blue from "../../assets/discoverUs/1TS.png"
import orange from "../../assets/discoverUs/TT3.png"
import yellow from "../../assets/discoverUs/TT2.png"
import { graphql, useStaticQuery } from 'gatsby';
import styled from "styled-components"
import VHCenter from "../elements/VHCenter"
import smartVector from "../../assets/discoverUs/smart-object.png"
import mindVector from "../../assets/discoverUs/mind.png"
import team1 from "../../assets/discoverUs/team1.png"
import team2 from "../../assets/discoverUs/team2.png"
import find1 from "../../assets/discoverUs/find1.png"
import find2 from "../../assets/discoverUs/find2.png"
import './approach.css'

const Img = styled.img`
    width: 100%;
    height:100%;
    cursor: pointer;
`;
const P = styled.p`
    /* font-weight:520; */
    font-size:19px;
    text-align:center;
    color: #4b4a4a;
    font-family:"avenir-medium";

`

export default function Approach() {

        const data = useStaticQuery(graphql`
        query {
            discoverApproach: allContentfulDiscoverApproach(
              filter: { path: { eq: "/discover" } }
              sort: { fields: orderId }
            ) {
              edges {
                node {
                  orderId
                  heading
                  text1 {
                    childContentfulRichText {
                      html
                    }
                  }
                  text2 {
                    childContentfulRichText {
                      html
                    }
                  }
                  icons {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }              
    `)
    console.log(data.discoverApproach.edges[1])
    const findText1 = data.discoverApproach.edges[0].node.text1.childContentfulRichText.html
    const findText2 = data.discoverApproach.edges[0].node.text2.childContentfulRichText.html
    const findImg = data.discoverApproach.edges[0].node.icons[2].fluid.src
    const findImg1 = data.discoverApproach.edges[0].node.icons[0].fluid.src
    const findImg2 = data.discoverApproach.edges[0].node.icons[1].fluid.src
    const findHeading = data.discoverApproach.edges[0].node.heading

    const teamText1 = data.discoverApproach.edges[1].node.text1.childContentfulRichText.html
    const teamText2 = data.discoverApproach.edges[1].node.text2.childContentfulRichText.html
    const teamImg = data.discoverApproach.edges[1].node.icons[2].fluid.src
    const teamImg1 = data.discoverApproach.edges[1].node.icons[0].fluid.src
    const teamImg2 = data.discoverApproach.edges[1].node.icons[1].fluid.src
    const teamHeading = data.discoverApproach.edges[1].node.heading

    const transformText1 = data.discoverApproach.edges[2].node.text1.childContentfulRichText.html
    const transformText2 = data.discoverApproach.edges[2].node.text2.childContentfulRichText.html
    const transformImg = data.discoverApproach.edges[2].node.icons[2].fluid.src
    const transformImg1 = data.discoverApproach.edges[2].node.icons[0].fluid.src
    const transformImg2 = data.discoverApproach.edges[2].node.icons[1].fluid.src
    const transformHeading = data.discoverApproach.edges[2].node.heading

    return (
        <div>
            <div className="container" style={{ marginTop: "6%" }}>
                <div class="row center">
                    <div class="col-3 col-sm-3 col-md-3 sm-div" >
                        <Img data-toggle="modal" data-target="#find" className="circle-img" src={findImg} />
                        <div class="modal fade" id="find" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content">
                                    <div style={{ borderBottom: '2px solid black' }} className="modal-header">
                                        <div className="container center">
                                            <div><h1 style={{ color: "#ed6c2b", }} >{findHeading}</h1></div>
                                        </div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span style={{ color: "black", }} aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div className="row">
                                            <div className="col-sm-6 ">
                                                <div className="center mt-3 mb-3" style={{ height: "170px" }}>
                                                    <img width="140px" height="120px" src={findImg1} />
                                                </div>
                                                <div className="center">
                                                    <div style={{ width: "70%" }}>
                                                        <P><span dangerouslySetInnerHTML={{__html: findText1}}></span></P>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6" >
                                                <div className="center mt-3 mb-3" style={{ height: "170px" }}>
                                                    <img width="110px" height="130px" src={findImg2} />
                                                </div>
                                                <div className="center">
                                                    <div style={{ width: "70%" }}>
                                                        <P><span dangerouslySetInnerHTML={{__html: findText2}}></span></P>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 offset-1 col-sm-3 offset-sm-1 col-md-3 offset-md-1 sm-div">
                        <Img data-toggle="modal" data-target="#team" className="circle-img" src={teamImg} />
                        <div class="modal fade" id="team" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content">
                                    <div style={{ borderBottom: '2px solid black' }} className="modal-header">
                                        <div className="container center">
                                            <div><h1 style={{ color: "#feb600" }} >{teamHeading}</h1></div>
                                        </div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span style={{ color: "black", }} aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div className="row">
                                            <div className="col-sm-6 ">
                                                <div className="center mt-3 mb-3" style={{ height: "170px" }}>
                                                    <img width="150px" height="150px" src={teamImg1} />
                                                </div>
                                                <div className="center">
                                                    <div style={{ width: "70%", }}>
                                                        <P><span dangerouslySetInnerHTML={{__html: teamText1}}></span></P>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6" >
                                                <div className="center mt-3 mb-3" style={{ height: "170px" }}>
                                                    <img width="150px" height="140px" src={teamImg2} />
                                                </div>
                                                <div className="center">
                                                    <div style={{ width: "70%", }}>
                                                        <P><span dangerouslySetInnerHTML={{__html: teamText2}}></span></P>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 offset-1 col-sm-3 offset-sm-1 col-md-3 offset-md-1 sm-div">
                        <Img data-toggle="modal" data-target="#transform" className="circle-img" src={transformImg} />
                        <div class="modal fade" id="transform" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content">
                                    <div style={{ borderBottom: '2px solid black' }} className="modal-header">
                                        <div className="container center">
                                            <div><h1 style={{ color: "#85b8cd" }} >{transformHeading}</h1></div>
                                        </div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span style={{ color: "black", }} aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div className="row">
                                            <div className="col-sm-6 ">
                                                <div className="center mt-3 mb-3" style={{ height: "170px" }}>
                                                    <img width="300px" height="150px" src={transformImg1} />
                                                </div>
                                                <div className="center">
                                                    <div style={{ width: "70%", }}>
                                                        <P><span dangerouslySetInnerHTML={{__html: transformText1}}></span></P>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6" >
                                                <div className="center mt-3 mb-3" style={{ height: "170px" }}>
                                                    <img width="350px" height="160px" src={transformImg2} />
                                                </div>
                                                <div className="center">
                                                    <div style={{ width: "70%", }}>
                                                        <P><span dangerouslySetInnerHTML={{__html: transformText2}}></span></P>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
