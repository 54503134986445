import React from 'react'

import VHCenter from "../elements/VHCenter"

export default function Values({ heading, icon, text, width, height }) {
    return (
        <div className="col-sm-12 col-md-3 text-center">
            <div className="" style={{ width: "100px", height: "100px", margin: "auto" }}>
                <VHCenter>
                    <img width={width} height={height} src={icon} alt="Sheild" />
                </VHCenter>
            </div>
            <h6 className="avenir-black" style={{ marginTop: 0, fontWeight: 900, color: "rgb(97, 94, 94)", fontSize: "17px" }}>{heading}</h6>
            {/* <h6 className="avenir-heavy ">{heading}</h6> */}
            <div className="avenir-medium" style={{ fontSize: "17px" }} dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    )
}
