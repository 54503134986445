import { Link, graphql, useStaticQuery } from "gatsby"

import React, { useRef, useState } from "react"
import styled from "styled-components"
import CenterText from "../elements/CenterText"
import "./help.css"
import icon1 from './ICONS AND VECTORS/8.png'
import icon2 from './ICONS AND VECTORS/9.png'
import icon3 from './ICONS AND VECTORS/10.png'
import icon4 from './ICONS AND VECTORS/stars.png'
import icon5 from './ICONS AND VECTORS/11.png'
import icon6 from './ICONS AND VECTORS/12.png'

const OuterDiv = styled.div`
  padding-bottom: 5%;
`
const ColorDiv = styled.div`
  color: rgb(97, 94, 94);
  :hover{
    color: #ffc107;
  }
`
export default function Help({ heading, width, height, icon, text }) {

  return (
    <div className="width">
      <div class="subnav imgStyles text-center">
        <button class="subnavbtn" >
          <img src={icon} height={height} width={width} />
          <ColorDiv><h6 className="avenir-black" style={{ marginTop: 50 }}>{heading}</h6></ColorDiv>
          <span></span>
        </button>
        <div class="subnav-content ">
          <div className="textStyles container text-justify" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>
    </div>
  )
}
