import React from 'react'
import coaching from "../../assets/discoverUs/coaching.png"
import consulting from "../../assets/discoverUs/consulting.png"
import tools from "../../assets/discoverUs/tools.png"
import keynotes from "../../assets/discoverUs/keynotes.png"
import fitness from "../../assets/discoverUs/fitness.png"
import workshops from "../../assets/discoverUs/workshops.png"
import VHCenter from "../elements/VHCenter"

export default function Methods({ icon, heading, width, height }) {
    return (
        <div className="col-sm-6  col-md-4 col-lg-2 col-6 text-center">
            <div style={{ width: "100px", height: "100px", margin: "auto" }}>
                <VHCenter>
                    <img width={width} height={height} src={icon} alt={heading} />
                </VHCenter>
            </div>
            <h6 className="avenir-black" style={{ marginTop: 0, fontWeight: 900, color: "rgb(97, 94, 94)", fontSize: "17px" }}>{heading}</h6>
        </div>
    )
}
