import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import mindfit from "../../assets/discoverUs/mindfit.png"
import mindfitPopup from "../../assets/discoverUs/mindftt-popup.png"
import bodyfit from "../../assets/discoverUs/bodyfit.png"
import bodyfitPopup from "../../assets/discoverUs/bodyftt-popup.png"
import soulfit from "../../assets/discoverUs/soulfit.png"
import soulfttPopup from '../../assets/discoverUs/soulftt-popup.png'
import { Link } from 'gatsby';
import VHCenter from '../elements/VHCenter';
import styled from "styled-components"
import "./section.css"


const Div = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: ${(props) => props.color};
    margin: auto ;
    @media (max-width: 768px) {
    width: 60%;
  }
`;

export default function Section() {

    const data = useStaticQuery(graphql`
    query {
        discoverPopups: allContentfulDiscoverPopups(
            filter: { path: { eq: "/discover" } }
            sort: { fields: orderId }
          ) {
            edges {
              node {
                heading
                subHeading
                body {
                  childContentfulRichText {
                    html
                  }
                }
                popupBody {
                  childContentfulRichText {
                    html
                  }
                }
                icons {
                  fluid {
                    src
                  }
                }
                buttonText
              }
            }
          }
    }
  `)

    const mindfttHeading = data.discoverPopups.edges[0].node.heading
    const mindfttSubHeading = data.discoverPopups.edges[0].node.subHeading
    const mindfttButtonText = data.discoverPopups.edges[0].node.buttonText
    const mindfttText = data.discoverPopups.edges[0].node.body.childContentfulRichText.html
    const mindfttImg = data.discoverPopups.edges[0].node.icons[0].fluid.src
    const mindfttPopupImg = data.discoverPopups.edges[0].node.icons[1].fluid.src
    const mindfttPopupText = data.discoverPopups.edges[0].node.popupBody.childContentfulRichText.html

    const bodyfttHeading = data.discoverPopups.edges[1].node.heading
    const bodyfttSubHeading = data.discoverPopups.edges[1].node.subHeading
    const bodyfttButtonText = data.discoverPopups.edges[1].node.buttonText
    const bodyfttText = data.discoverPopups.edges[1].node.body.childContentfulRichText.html
    const bodyfttImg = data.discoverPopups.edges[1].node.icons[0].fluid.src
    const bodyfttPopupImg = data.discoverPopups.edges[1].node.icons[1].fluid.src
    const bodyfttPopupText = data.discoverPopups.edges[1].node.popupBody.childContentfulRichText.html

    const soulfttHeading = data.discoverPopups.edges[2].node.heading
    const soulfttSubHeading = data.discoverPopups.edges[2].node.subHeading
    const soulfttButtonText = data.discoverPopups.edges[2].node.buttonText
    const soulfttText = data.discoverPopups.edges[2].node.body.childContentfulRichText.html
    const soulfttImg = data.discoverPopups.edges[2].node.icons[0].fluid.src
    const soulfttPopupImg = data.discoverPopups.edges[2].node.icons[1].fluid.src
    const soulfttPopupText = data.discoverPopups.edges[2].node.popupBody.childContentfulRichText.html

    const maxCount = 135

    return (
        <div className="container" style={{ marginTop: "5%", }}>
            <div className="row m-1 ">
                <div className="col-sm-12 col-md-6 col-lg-4 text-center outer-box" style={{ marginBottom: "7%" }}>
                    <Div width="90%" height="200px" color="#ed6c2b" >
                        <VHCenter>
                            <img width="122px" height="130px" src={mindfttImg} alt="mind fitness" />
                        </VHCenter>
                    </Div>
                    <div className="inner-box" >
                        <div>
                            <h4 className="avenir-black" style={{ color: "#ed6c2b", marginTop: "5%" }} dangerouslySetInnerHTML={{ __html: mindfttHeading }}></h4>
                            <div className="avenir-black body-font-size" style={{ color: "#ed6c2b", marginTop: "5%" }} dangerouslySetInnerHTML={{ __html: mindfttSubHeading }}></div>
                        </div>
                        {
                            mindfttText.length > maxCount ?

                                <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: mindfttText.substring(0, maxCount) + "..." }}></div>
                                :
                                <div className="avenir-roman body-font-size text-justify" className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: mindfttText }}></div>

                        }

                    </div>
                    <div className="text-right">
                        <Link className="avenir-heavy" style={{ color: "#ed6c2b" }} data-toggle="modal" data-target="#mindftt" dangerouslySetInnerHTML={{ __html: mindfttButtonText }}></Link>
                    </div>
                    <div className="modal fade" id="mindftt" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content" >
                                <div className="m-3">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span className="black-btn" aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ width: "90%", margin: "auto" }}>
                                    <Div width="66.3%" height="167px" color="#ed6c2b">
                                        <VHCenter>
                                            <img width="143px" height="150px" src={mindfttPopupImg} alt="mind fitness" />
                                        </VHCenter>
                                    </Div>
                                    <div className="avenir-black body-font-size mb-3" style={{ color: "#ed6c2b", marginTop: "5%" }}>
                                        <div dangerouslySetInnerHTML={{ __html: mindfttSubHeading }}></div>
                                    </div>
                                    <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: mindfttPopupText }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 text-center outer-box" style={{ marginBottom: "7%" }}>
                    <Div width="90%" height="200px" color="#e9ab22">
                        <VHCenter>
                            <img width="100px" height="130px" src={bodyfttImg} alt="body fitness" />
                        </VHCenter>
                    </Div>
                    <div className="inner-box">
                        <div>
                            <h4 className="avenir-black" className="avenir-black" style={{ color: "#feb600", marginTop: "5%" }} dangerouslySetInnerHTML={{ __html: bodyfttHeading }}></h4>
                            <div className="avenir-black body-font-size" style={{ color: "#feb600", marginTop: "5%" }} dangerouslySetInnerHTML={{ __html: bodyfttSubHeading }}></div>
                        </div>
                        {
                            bodyfttText.length > maxCount ?

                                <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: bodyfttText.substring(0, maxCount) + "..." }}></div>
                                :
                                <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: bodyfttText }}></div>
                        }

                    </div>
                    <div className="text-right">
                        <Link className="avenir-heavy" style={{ color: "#feb600" }} data-toggle="modal" data-target="#bodyftt" dangerouslySetInnerHTML={{ __html: bodyfttButtonText }} ></Link>
                    </div>
                    <div className="modal fade" id="bodyftt" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="m-3">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span className="black-btn" aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ width: "90%", margin: "auto" }}>
                                    <Div width="66.3%" height="167px" color="#feb600">
                                        <VHCenter>
                                            <img width="165" height="165" src={bodyfttPopupImg} alt="body fitness" />
                                        </VHCenter>
                                    </Div>
                                    <div className="avenir-roman body-font-size mb-3" style={{ color: "#feb600", marginTop: "5%" }}>
                                        <div dangerouslySetInnerHTML={{ __html: bodyfttSubHeading }}></div>
                                    </div>
                                    <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: bodyfttPopupText }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 text-center outer-box" style={{ marginBottom: "7%" }}>
                    <Div width="90%" height="200px" color="#85b8cd">
                        <VHCenter>
                            <img width="138px" height="130px" src={soulfttImg} alt="soulfit fitness" />
                        </VHCenter>
                    </Div>
                    <div className="inner-box">
                        <div>
                            <h4 className="avenir-black" className="avenir-black" style={{ color: "#85b8cd", marginTop: "5%" }} dangerouslySetInnerHTML={{ __html: soulfttHeading }}></h4>
                            <div className="avenir-black body-font-size" style={{ color: "#85b8cd", marginTop: "5%" }} dangerouslySetInnerHTML={{ __html: soulfttSubHeading }}></div>
                        </div>
                        {
                            soulfttText.length > maxCount ?
                                <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: soulfttText.substring(0, maxCount) + "..." }}></div>
                                :
                                <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: soulfttText }}></div>


                        }
                    </div>
                    <div className="text-right">
                        <Link className="avenir-heavy" style={{ color: "#85b8cd" }} data-toggle="modal" data-target="#soulftt" dangerouslySetInnerHTML={{ __html: soulfttButtonText }} ></Link>
                    </div>
                    <div className="modal fade" id="soulftt" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="m-3">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span className="black-btn" aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ width: "90%", margin: "auto" }}>
                                    <Div width="66.3%" height="167px" color="#85b8cd">
                                        <VHCenter>
                                            <img width="150px" height="150px" src={soulfttPopupImg} alt="body fitness" />
                                        </VHCenter>
                                    </Div>
                                    <div className="avenir-roman body-font-size mb-3" style={{ color: "#85b8cd", marginTop: "5%" }}>
                                        <div dangerouslySetInnerHTML={{ __html: soulfttSubHeading }}></div>
                                    </div>
                                    <div className="avenir-roman body-font-size text-justify" dangerouslySetInnerHTML={{ __html: soulfttPopupText }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
