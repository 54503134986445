import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import ImgHeader from "../components/ImgHeader"
// import Methods from "../assets/discoverUs/methods.png"
import CenterDiv from "../components/elements/CenterDiv"
import VHCenter from "../components/elements/VHCenter"
import Section from "../components/discoverUs/Section"
import SEO from "../components/seo"
import Approach from "../components/discoverUs/Approach";
import Methods from "../components/discoverUs/Methods"
import Title from '../components/elements/Title'
import TitleDiv from '../components/elements/TitleDiv'
import ListingComponent from '../components/ListingComponent'
import image from "../assets/discoverUs/Discover-Us.jpg"


const Discover = ({ data }) => {

  const { title } = data.ImgHeaderQuery
  const background = data.ImgHeaderQuery.backgroundImg.fluid.src

  const firstTitle = data.TitleDivQuery.edges[0].node.header
  const secondTitle = data.TitleDivQuery.edges[1].node.header
  const thirdTitle = data.TitleDivQuery.edges[2].node.header
  const fourthTitle = data.TitleDivQuery.edges[3].node.header
  const fifthTitle = data.TitleDivQuery.edges[4].node.header

  const philosphyBlock = data.contentBlock.edges[0].node.body.childContentfulRichText.html
  const approachBlock = data.contentBlock.edges[1].node.body.childContentfulRichText.html



  return (
    <Layout>
      <SEO title="Discover Us" />
      <ImgHeader position="center center" image={background} title={title} />
      <TitleDiv color="#febf20" style={{ marginTop: "0%" }}>
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <CenterDiv className="container" >
        <div className="avenir-roman font-size" style={{ color: "#4b4a4a" }} dangerouslySetInnerHTML={{ __html: philosphyBlock }}></div>
      </CenterDiv>
      <Section />
      <TitleDiv color="#85b8cd">
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div style={{ width: "51.9%" }} className="container">
        <div className="avenir-roman font-size text-center" dangerouslySetInnerHTML={{ __html: approachBlock }}>
        </div>
      </div>
      <Approach />
      <TitleDiv color="#ed6c2b">
        <VHCenter>
          <div>
            <p className="avenir-medium text-center" style={{ color: "white", fontSize: "20px" }}>
              <span dangerouslySetInnerHTML={{ __html: thirdTitle }}></span>
              <span className="avenir-black" dangerouslySetInnerHTML={{ __html: fourthTitle }}>
                {/* <div ></div> */}
              </span>
              <span dangerouslySetInnerHTML={{ __html: fifthTitle }}></span>
            </p>
          </div>
        </VHCenter>
      </TitleDiv>
      <div style={{ marginBottom: "0%" }} className="container">
        <img className="w-100" src={Methods} />
      </div>
      <ListingComponent path="/discover" />
    </Layout>
  )
}

export default Discover

export const query = graphql`
    query DiscoverPageQuery {
        ImgHeaderQuery: contentfulImgHeader(path: { eq: "/discover" }) {
            title
            path
            backgroundImg {
              fluid(quality: 100) {
                src
              }
            }
          }
          TitleDivQuery: allContentfulTitleDiv(
            filter: { path: { eq: "/discover" }}, 
            sort: { fields: orderId }
            )
          {
            edges {
              node {
                orderId
                header 
              }
            }
          }
          contentBlock: allContentfulBlocks(
            filter: { path: { eq: "/discover" } }
            sort: { fields: orderId }
          ) {
            edges {
              node {
                body {
                  childContentfulRichText {
                    html
                  }
                }
                image{
                    fluid{
                        src
                    }
                }
              }
            }
          }
    }
`

